<template>
  <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" class="campaigns-table" :responsive="true" :items="campaigns" :fields="tableFields" no-local-sorting @sort-changed="sortChangeEmit">
    <template #cell(id)="row">
      <p style="word-break: normal; margin: 0;">{{ row.item.id }}</p>
    </template>

    <template #cell(type)="row">
      <!--      {{ row.item.advertisement_type.map((type) => type.name).join(', ') }}-->
      <b-badge v-for="type of row.item.advertisement_types" :key="type.id" class="badge" variant="primary">
        {{ type.name }}
      </b-badge>
    </template>

    <template #cell(limit_init_day)="row">
      <p v-if="row.item.limit_init_day">{{row.item.limit_init_day}}</p>
      <p v-else>нет</p>
    </template>

    <template #cell(advertiser)="row">
      {{ row.item.advertiser.name }}
    </template>

    <template #cell(is_active)="row">
<!--      <b-badge pill :variant="row.item.is_active ? 'light-success' : 'light-danger'">-->
<!--        {{ row.item.is_active ? 'Активен' : 'Неактивен' }}-->
<!--      </b-badge>-->
      <b-form-checkbox v-model="row.item.is_active" variant="success" switch @change="handleToggleActive($event, row.item)" />
    </template>

    <template #cell(actions)="row">
      <b-dropdown right variant="flat-primary" no-caret>
        <template #button-content>
          <more-vertical-icon />
        </template>

        <b-dropdown-item v-if="can('manage', 'advertising-campaigns')" @click="handleEdit(row.item.id)"> Редактировать </b-dropdown-item>

<!--        <b-dropdown-item v-else-if="can('manage', 'advertising-campaigns-placements')" @click="handleEdit(row.item.id)"> Редактировать размещения </b-dropdown-item>-->

        <b-dropdown-item v-if="can('read', 'placements')" :to="{ name: 'placements', params: { campaigns: [row.item.id] }}"> Размещения </b-dropdown-item>

        <b-dropdown-item @click="handleCopyCampaign(row.item.id)">Дублировать</b-dropdown-item>

        <b-dropdown-item v-if="can('read', 'manager-statistic')" :to="{ name: 'manager-statistic', params: { campaigns: [row.item.id] }}"> Статистика </b-dropdown-item>

        <b-dropdown-item v-if="!can('read', 'manager-statistic') && can('read', 'advertiser-statistic')" :to="{ name: 'advertiser-statistic', params: { campaigns: [row.item.id] }}">Статистика </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>

<script>
import { BTable, BBadge, BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
import { MoreVerticalIcon } from 'vue-feather-icons'
import {useUtils as useAclUtils} from "@core/libs/acl"
import {computed} from "@vue/composition-api"

const fields = [
  { key: 'id', label: 'ID', sortable: true },
  { key: 'name', label: 'Название', sortable: true },
  { key: 'type', label: 'Тип' },
  { key: 'qps', label: 'QPS', sortable: true},
  { key: 'main_cpm', label: 'CPM', sortable: true },
  { key: 'weight', label: 'Вес', sortable: true },
  { key: 'advertiser', label: 'DSP' },
  { key: 'limit_init_day', label: 'Лимит в сутки', sortable: true },
  { key: 'daily_limit_user', label: 'Частотность', sortable: true },
  { key: 'is_active', label: 'Статус', sortable: true },
  { key: 'actions', label: 'Действия' },
]

const advertiserFields = [
  { key: 'id', label: 'ID', sortable: true },
  { key: 'name', label: 'Имя', sortable: true },
  { key: 'type', label: 'Тип' },
  { key: 'qps', label: 'QPS', sortable: true},
  { key: 'main_cpm', label: 'CPM', sortable: true },
  { key: 'limit_init_day', label: 'Лимит в сутки', sortable: true },
  { key: 'actions', label: 'Действия' }
]

export default {
  components: {
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    MoreVerticalIcon
  },
  props: {
    campaigns: {
      type: Array,
      required: false,
      default: () => []
    },
    sortBy: {
      type: String,
    },
    sortDesc: {
      type: Boolean,
    }
  },
  data() {
    return {
      fields
    }
  },
  methods: {
    handleEdit(id) {
      this.$emit('edit', id)
    },
    sortChangeEmit(sort) {
      if(!sort.sortBy) return;
      this.$emit('sort-change', sort);
    },
    handleToggleActive(event, data) {
      this.$emit('toggle-active', data)
    },
    handleCopyCampaign(id) {
      this.$emit('copy-campaign', id);
    }
  },

  setup() {
    const {can} = useAclUtils();

    const tableFields = computed(() => {
      return can('read', 'advertiser-name') ? fields : advertiserFields;
    })
    return {
      can,
      tableFields
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  margin: 3px;
}
</style>
<style lang="scss">
.campaigns-table {
  padding-bottom: 2rem;
    & th[aria-colindex="3"] {
      width: 100px;
    }
}

</style>
